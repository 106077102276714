<template>
  <date-range-picker
        :locale-data="{ firstDay: 1, format: 'dd.mm.yyyy' }"
        :show-dropdowns="true"
        :auto-apply="true"
        :ranges="datepicker_ranges"
        :opens="opens"
        :disabled="disabled"
        :class="{ disabled }"

        :date-range="cRange"
        @update="changed($event)"
    />
</template>

<script>
import dayjs from '@/plugins/day'
import DateRangePicker from 'vue2-daterange-picker'

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
export default {
    name: 'DateRangeForm',
    props: {
        range: {
            startDate: Object,
            endDate: Object
        },
        opens: {
            default: 'center'
        },
        disabled: {
            default: false
        }
    },
    data: () => {
        return {
            cRange: {
                startDate: null,
                endDate: null
            },
            datepicker_ranges: {
                'Today': [dayjs().startOf('day').toDate(), dayjs().endOf('day').toDate()],
                'Yesterday': [dayjs().add(-1, 'day').startOf('day').toDate(), dayjs().add(-1, 'day').endOf('day').toDate()],
                'Last week': [dayjs().add(-7, 'day').startOf('day').toDate(), dayjs().endOf('day').toDate()],
                'Last month': [dayjs().add(-30, 'day').startOf('day').toDate(), dayjs().endOf('day').toDate()],
                'Last year': [dayjs().add(-365, 'day').startOf('day').toDate(), dayjs().endOf('day').toDate()],
            }
        }
    },
    computed: {

    },
    mounted() {
        this.cRange = this.range
    },
    methods: {
        changed($event) {
            $event.startDate = dayjs($event.startDate).startOf('day').toDate()
            $event.endDate = dayjs($event.endDate).endOf('day').toDate()

            this.$emit('input', $event)
        }
    },
    components: {
        DateRangePicker
    }
}
</script>

<style>
.vue-daterange-picker.disabled .reportrange-text {
    cursor: not-allowed;
}
</style>